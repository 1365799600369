import Vue from 'vue'
import App from './App'
import router from './router'
// import VeeValidate from 'vee-validate';
import CoreuiVue from '@coreui/vue'
import Vuex from 'vuex'
import store from './store'
import { iconsSet as icons } from './assets/icons/icons.js'
import JsonExcel from "vue-json-excel";

window.EventBus = new Vue();

Vue.config.performance = true
Vue.use(Vuex)
Vue.use(CoreuiVue)
Vue.component("downloadExcel", JsonExcel);

new Vue({
  el: '#app',
  router,
  icons,
  store,
  template: '<App/>',
  components: {
    App
  },
})
