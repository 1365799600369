import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import { auth } from './authModule';


Vue.use(Vuex);

export default new Vuex.Store(
  {
    /* Godd for local debugging, bad for performance in production! */
    strict: process.env.NODE_ENV !== 'production',
    modules: {
      auth
    },
    plugins: [createPersistedState({
      storage: window.sessionStorage,
    })],
    state: {
      // insuranceCountry: '',
      insurance: {
        id: null,
        country: [],
        initialDate: 'Thu Feb 20 2020 17:38:00 GMT+0000 (Western European Standard Time)',
        finalDate: 'Thu Feb 27 2020 17:38:00 GMT+0000 (Western European Standard Time)',
        peopleQuantity: '1',
        plan: '',
        price: '',
        person: {
          id: null,
          name: '',
          surname: '',
          email: '',
          birthday: '',
          mobileNumber: '',
        },
        address: {
          line1: '',
          line2: '',
          city: '',
          location: '',
          postalCode: '',
          postalCodeSegurado: '',
          adderessSegurado: '',
          citySegurado: '',
          locationSegurado: '',
          countryOfAddress: 'Portugal',
        },
        terms: '',
        // ...
      },
      emailForQuotation: '',
      // ...
    },
    
    mutations: {
      setInsuranceId(state, payload) {
        state.insurance.id = payload.insuranceId;
      },
      setInsuranceCountry(state, payload) {
        state.insurance.country = payload.insuranceCountry;
      },
      setInsuranceInitialDate(state, payload) {
        state.insurance.initialDate = payload.insuranceInitialDate;
      },
      setInsuranceFinalDate(state, payload) {
        state.insurance.finalDate = payload.insuranceFinalDate;
      },
      setInsurancePeopleQuantity(state, payload) {
        state.insurance.peopleQuantity = payload.insurancePeopleQuantity;
      },
      setInsurancePlan(state, payload) {
        state.insurance.plan = payload.insurancePlan;
      },
      setInsurancePrice(state, payload) {
        state.insurance.price = payload.insurancePrice;
      },
      setEmailForQuotation(state, payload) {
        state.emailForQuotation = payload.emailForQuotation;
      },
      setInsurancePersonName(state, payload) {
        state.insurance.person.name = payload.insurancePersonName;
      },
      setInsurancePersonSurname(state, payload) {
        state.insurance.person.surname = payload.insurancePersonSurname;
      },
      setInsurancePersonEmail(state, payload) {
        state.insurance.person.email = payload.insurancePersonEmail;
      },
      setInsurancePersonBirthday(state, payload) {
        state.insurance.person.birthday = payload.insurancePersonBirthday;
      },
      setInsurancePersonMobileNumber(state, payload) {
        state.insurance.person.mobileNumber = payload.insurancePersonMobileNumber;
      },
      setInsuranceAddressLine1(state, payload) {
        state.insurance.address.line1 = payload.insuranceAddressLine1;
      },
      setInsuranceAddressLine2(state, payload) {
        state.insurance.address.line2 = payload.insuranceAddressLine2;
      },
      setInsuranceAddressSegurado(state, payload) {
        state.insurance.address.adderessSegurado = payload.insuranceAddressSegurado;
      },
      setInsuranceAddressCity(state, payload) {
        state.insurance.address.city = payload.insuranceAddressCity;
      },
      setInsuranceAddressCitySegurado(state, payload) {
        state.insurance.address.citySegurado = payload.insuranceAddressCitySegurado;
      },
      setInsuranceAddressLocation(state, payload) {
        state.insurance.address.location = payload.insuranceAddressLocation;
      },
      setInsuranceAddressLocationSegurado(state, payload) {
        state.insurance.address.locationSegurado = payload.insuranceAddressLocationSegurado;
      },
      setInsuranceAddressPostalCode(state, payload) {
        state.insurance.address.postalCode = payload.insuranceAddressPostalCode;
      },
      setInsuranceAddressPostalCodeSegurado(state, payload) {
        state.insurance.address.postalCodeSegurado = payload.insuranceAddressPostalCodeSegurado;
      },
      setInsuranceTerms(state, payload) {
        state.insurance.terms = payload.insuranceTerms;
      },
      
      
      // ...
    }
  });