import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')


const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const UsersAdmin = () => import('@/views/users/Users')
const UserAdmin = () => import('@/views/users/User')
const NewUserAdmin = () => import('@/views/users/New')

// Profile
const Profile = () => import('@/views/profile/Profile')

// Clients
const Clients = () => import('@/views/clients/Clients')
const Client = () => import('@/views/clients/Client')
const Sales = () => import('@/views/pages/Sales')

const Report = () => import('@/views/pages/Report')

// Prices
const Prices = () => import('@/views/prices/Prices')
const NewPrice = () => import('@/views/prices/New')
const Price = () => import('@/views/prices/Price')

// Promocodes
const Promocodes = () => import('@/views/promocodes/Promocodes')
const NewPromocode = () => import('@/views/promocodes/New')
const Promocode = () => import('@/views/promocodes/Promocode')

// Subscribers
const Subscribers = () => import('@/views/subscribers/Subscribers.vue')

Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})



function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'sales',
          name: 'Sales',
          component: Sales
        },
        {
          path: 'subscribers',
          meta: { label: 'Subscribers' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Subscribers,
            }
          ]
        },
        {
          path: 'users',
          meta: { label: 'Users' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: UsersAdmin,
            },
            {
              path: 'new',
              meta: { label: 'User New' },
              name: 'User',
              component: NewUserAdmin,
            },
            {
              path: ':id',
              meta: { label: 'User Details' },
              name: 'User',
              component: UserAdmin,
            },
          ]
        },
        {
          path: 'report',
          name: 'Report',
          component: Report
        },
        {
          path: 'clients',
          meta: { label: 'Clients' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Clients,
            },
            {
              path: ':id',
              meta: { label: 'Client - Insurances' },
              name: 'Client',
              component: Client,
            },
          ]
        },
        {
          path: 'prices',
          meta: { label: 'Prices' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Prices,
            },
            {
              path: 'new',
              meta: { label: 'Price New' },
              name: 'NewPrice',
              component: NewPrice,
            },
            {
              path: ':id',
              meta: { label: 'Price Details' },
              name: 'Price',
              component: Price,
            },
          ]
        },
        {
          path: 'profile',
          meta: { label: 'Perfil' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Profile,
            },
          ]
        },
        {
          path: 'promocodes',
          meta: { label: 'Prices' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Promocodes,
            },
            {
              path: 'new',
              meta: { label: 'Promocode New' },
              name: 'NewPromocode',
              component: NewPromocode,
            },
            {
              path: ':id',
              meta: { label: 'Promocode Details' },
              name: 'Promocode',
              component: Promocode,
            },
          ]
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
      
    },
    

  ]
}